// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-tsx": () => import("./../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-education-tsx": () => import("./../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-patients-tsx": () => import("./../src/pages/new-patients.tsx" /* webpackChunkName: "component---src-pages-new-patients-tsx" */),
  "component---src-pages-office-tour-tsx": () => import("./../src/pages/office-tour.tsx" /* webpackChunkName: "component---src-pages-office-tour-tsx" */),
  "component---src-pages-services-tsx": () => import("./../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */)
}

